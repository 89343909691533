import React, { useContext } from 'react';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { PhoneField, Small } from '@ubeya/shared-web/components';
import { required } from '@ubeya/shared/utils/form';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '@ubeya/shared-web/hooks/useQueryParams';
import ErrorText from '@ubeya/shared-web/components/Input/ErrorText';
import useMfa from '../../hooks/useMfa';
import AuthLayout from '../AuthLayout';
import { Box, PrimaryRoundButton, SecondaryRoundButton, ButtonsContainer } from '../styled';

const SetupMfa = () => {
  const { t } = useContext(ConfigContext);
  const { push } = useHistory();
  const { mfaToken } = useQueryParams();
  const { setupMfa, setupMfaLoading, setupMfaError } = useMfa();

  const error = setupMfaError?.response?.data?.message;

  return (
    <AuthLayout>
      <Box
        onSubmit={({ mfaPhone }) => setupMfa({ mfaPhone, mfaToken })}
        title={t('setupMfaTitle')}
        inside
        displayErrors={false}>
        {({ submitting, values, submitErrors }) => {
          if (error && error === 'error__invalid_token') {
            return (
              <>
                <Small>{error}</Small>
                <PrimaryRoundButton onClick={() => push(`/login/admin`)}>{t('backToLoginCaps')}</PrimaryRoundButton>
              </>
            );
          }
          return (
            <>
              <Small>{t('requiredSetMfa')}</Small>
              <PhoneField
                name="mfaPhone"
                title={t('mfaPhone')}
                type="phone"
                validate={required}
                disableInitialDialCode
              />
              <ButtonsContainer>
                <PrimaryRoundButton
                  disabled={submitting || setupMfaLoading || !values?.mfaPhone}
                  isLoading={setupMfaLoading}>
                  {!setupMfaLoading && t('setupCaps')}
                </PrimaryRoundButton>
                <SecondaryRoundButton onClick={() => push(`/login/admin`)}>{t('backToLoginCaps')}</SecondaryRoundButton>
              </ButtonsContainer>
              {submitErrors !== 'invalid_token' && <ErrorText error={submitErrors} />}
            </>
          );
        }}
      </Box>
    </AuthLayout>
  );
};

export default SetupMfa;
