export const PLANS = [
  {
    name: 'Your current plan',
    price: 'Free',
    slug: 'Free',
    features: [
      'CRM',
      'Worker app',
      'Limited scheduling',
      'Limited timesheet',
      'Limited availability check',
      'Limited payroll',
      'Limited workflows',
      'Limited exports',
      'Email support'
    ]
  },
  {
    name: 'Basic',
    price: '£3',
    slug: 'Basic',
    features: [
      'Worker app',
      'Manager app',
      'Unlimited scheduling',
      'Unlimited timesheet',
      'Unlimited availability check',
      'Unlimited exports',
      'Full support'
    ],
    upgradeLink: 'basic'
  },
  {
    name: 'Pro',
    showRecommended: true,
    price: '£6',
    slug: 'Pro',
    features: [
      '1:1 onboarding sessions',
      'Unlimited payroll',
      'Unlimited workflows and automations',
      'Reports',
      'Customer success'
    ],
    upgradeLink: 'pro'
  },
  {
    name: 'Super',
    slug: 'Super',
    price: '£9',
    features: ['Regional branches management', 'Compliance', 'Payroll integration', 'ATS integration', 'API access'],
    upgradeLink: 'super'
  }
];
