import moment from 'moment';
import { API_DATE_FORMAT, API_DATE_TIME_FORMAT } from '../constants';

export const getDurationFromRange = (startTime, endTime) => {
  const diff = moment(endTime).diff(moment(startTime));
  return getDurationFromMilliseconds(diff);
};

export const getMillisecondsFromDecimal = (decimalValue) => Math.round(decimalValue * 60) * 60 * 1000;

export const getDurationFromMilliseconds = (ms, allowNegative = false) => {
  const dur = moment.duration(allowNegative ? Math.abs(ms) : ms, 'milliseconds');
  let hours = Math.floor(dur.asHours());
  const mins = Math.floor(dur.asMinutes()) - hours * 60;
  hours = hours < 0 ? 24 + hours : hours;

  return `${allowNegative && ms < 0 ? '-' : ''}${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `0${mins}` : mins}`;
};

export const isEndTimeBeforeStartTime = (startTime, endTime, includeEqualStartEndTIme = true) =>
  endTime.get('hour') < startTime.get('hour') ||
  (endTime.get('hour') === startTime.get('hour') &&
    (includeEqualStartEndTIme
      ? endTime.get('minute') <= startTime.get('minute')
      : endTime.get('minute') < startTime.get('minute')));

// update start time and end time with the correct date
export const getTimeRangeWithDates = ({
  date,
  startTime,
  endTime,
  checkBaseStartDate = false,
  // consider 10:00 start time and 10:00 end time as in different dates
  includeEqualStartEndTIme = true
}) => {
  const startDate = moment(date);
  const endDate = moment(date);
  const endDateWithExtraDay = moment(date);

  const startMoment = moment(startTime);
  const endMoment = moment(endTime);

  const start = startMoment.isValid()
    ? startDate.set({
        hour: startMoment.get('hour'),
        minute: startMoment.get('minute'),
        seconds: 0
      })
    : null;

  // get the endTime after adding a day
  const endTimeWithExtraDay =
    endTime && endMoment.isValid()
      ? endDateWithExtraDay.set({
          date: endDateWithExtraDay.add(1, 'day').get('date'),
          hour: endMoment.get('hour') || '',
          minute: endMoment.get('minute') || '',
          seconds: 0
        })
      : null;

  // get the endTime withouth adding a day
  const endTimeWithoutExtraDay =
    endTime && endMoment.isValid()
      ? endDate.set({
          date: endDate.get('date'),
          hour: endMoment.get('hour') || '',
          minute: endMoment.get('minute') || '',
          seconds: 0
        })
      : null;

  const endTimeBeforeStartTime = isEndTimeBeforeStartTime(startMoment, endMoment, includeEqualStartEndTIme);
  let addExtraDay = endTimeBeforeStartTime;

  /* conditions:
    1. startTime date is before the event date.
    2. endTime is before startTime (meaning there is a need to add extra day) 
    3. endTimeWithExtraDay is not null
  */
  const calaculateIfExtraDay =
    checkBaseStartDate && start && moment(startTime).isBefore(start) && endTimeBeforeStartTime && endTimeWithExtraDay;

  if (calaculateIfExtraDay) {
    //endTime needs to be +1 only if the total durtion after the addition does not exceeds 24 hours
    addExtraDay = !(moment.duration(endTimeWithExtraDay.diff(startTime, 'milliseconds')).asHours() > 24);
  }

  const end = addExtraDay ? endTimeWithExtraDay : endTimeWithoutExtraDay;

  return {
    startTime: start ? start.format(API_DATE_TIME_FORMAT) : null,
    endTime: end ? end.format(API_DATE_TIME_FORMAT) : null
  };
};

export const getDaysOfWeek = (startFrom, totalDays = 7) => {
  const startDate = moment(startFrom).startOf('week').startOf('day').subtract(1, 'day');

  return [...Array(totalDays)].map(() => {
    startDate.add(1, 'day');
    return {
      name: startDate.format('dddd').toLowerCase(),
      nameCapital: startDate.format('dddd'),
      day: startDate.format('ddd'),
      date: startDate.format('YYYY-MM-DD'),
      index: startDate.get('day') + 1,
      momentDate: startDate.clone()
    };
  });
};

export const getDateByDay = (startFrom) => {
  const firstDayInWeek = moment(startFrom).day();

  const startDate =
    firstDayInWeek === 1
      ? moment(startFrom).startOf('week').startOf('day').subtract(1, 'day')
      : moment(startFrom).startOf('week').startOf('day');

  return [...Array(7)].reduce((all, _, index) => {
    if (index === 0) {
      const addition = firstDayInWeek === 1 ? 6 : 0; // 1 is monday
      all[index] = moment(startFrom).startOf('week').startOf('day').add(addition, 'day').format(API_DATE_FORMAT);
    } else {
      startDate.add(1, 'day');
      all[index] = startDate.format(API_DATE_FORMAT);
    }

    return all;
  }, {});
};

export const ago = (date, t) => {
  const MINUTE = 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const WEEK = DAY * 7;
  const YEAR = WEEK * 52;

  const seconds = moment().diff(moment.utc(date), 'seconds');

  // const date = moment(value).format(`${dateFormat} ${timeFormat}`);

  if (seconds < MINUTE) {
    return t('aFewSecondsAgo');
  }

  const minutes = Math.floor(seconds / 60);
  if (seconds < HOUR) {
    return t('minutesAgo', { value: minutes });
  }

  const hours = Math.floor(minutes / 60);
  if (seconds < DAY) {
    return t('hoursAgo', { value: hours });
  }

  const days = Math.floor(hours / 24);
  if (seconds < WEEK) {
    return t('daysAgo', { value: days });
  }

  const weeks = Math.floor(days / 7);
  if (seconds < YEAR) {
    return t('weeksAgo', { value: weeks });
  }

  const years = Math.floor(weeks / 52);
  return t('yearsAgo', { value: years });
};

export const convertTimeStringToMoment = (time) => (time ? moment(`2000-12-31 ${time}`) : moment());

export const DEFAULT_START_TIME = '09:00:00';

export const DEFAULT_END_TIME = '17:00:00';

export const generateRange = ({ start, end, step = 'day', granularity = 'day', useUTC = true }) => {
  let pointer = useUTC ? moment.utc(start) : moment(start);
  const last = useUTC ? moment.utc(end) : moment(end);

  const dates = [];

  while (pointer.isSameOrBefore(last, granularity)) {
    const curr = useUTC ? moment.utc(pointer) : moment(pointer);

    dates.push(curr);
    pointer = pointer.add(1, step);
  }

  return dates;
};

export const roundTimeToNearest = ({ inputTime, roundTimeInMin, format }) => {
  const momentDate = moment(inputTime, format ?? API_DATE_TIME_FORMAT);

  if (roundTimeInMin) {
    const threshold = roundTimeInMin / 2;

    const remainder = momentDate.minutes() % roundTimeInMin;

    // rounding down
    if (remainder < threshold) {
      momentDate.subtract(remainder, 'minutes');
    } else {
      // rounding up
      momentDate.add(roundTimeInMin - remainder, 'minutes');
    }
    momentDate.set({ second: 0, millisecond: 0 });
  }

  return momentDate;
};

export const areRangesOverlapping = (timeRanges) => {
  for (let i = 0; i < timeRanges.length - 1; i++) {
    if (timeRanges[i].end.isAfter(timeRanges[i + 1].start)) {
      return true; // Overlap found
    }
  }
  return false; // No overlap
};

export const formatNumberToMinutes = (minutes, type) => {
  switch (type) {
    case 'minutes':
      return minutes;
    case 'hours':
      return minutes * 60;
    default:
      return minutes * 60 * 24;
  }
};

export const formatMinutesToString = (minutes) => {
  if (minutes < 60) {
    return `${minutes} minutes`;
  }
  if (minutes < 60 * 24) {
    return `${minutes / 60} hours`;
  }
  return `${minutes / 60 / 24} days`;
};
