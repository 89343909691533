import React, { useCallback, useContext, useState } from 'react';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { CheckBox, Small } from '@ubeya/shared-web/components';
import { required } from '@ubeya/shared/utils/form';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParams } from '@ubeya/shared-web/hooks/useQueryParams';
import usePersistedState from '@ubeya/shared-web/hooks/usePersistedState';
import ErrorText from '@ubeya/shared-web/components/Input/ErrorText';
import AuthLayout from '../AuthLayout';
import {
  Box,
  PrimaryRoundButton,
  NumberFieldNoArrows,
  SecondaryRoundButton,
  ButtonsContainer,
  DefaultRoundButton
} from '../styled';
import useMfa from '../../hooks/useMfa';

const VerifyMfa = () => {
  const { t } = useContext(ConfigContext);
  const { push } = useHistory();
  const { state } = useLocation();
  const { mfaToken } = useQueryParams();
  const {
    verifyMfa,
    verifyMfaError,
    verifyMfaLoading,
    verifyReset,
    generateMfa,
    generateMfaError,
    generateMfaLoading
  } = useMfa();

  const [rememberMe, setRememberMe] = useState(true);
  const [_, setRememberMeToken] = usePersistedState('remember-me-token', '');
  const mfaPhone = state?.mfaPhone ? state.mfaPhone : null;

  const handleVerifyMfa = async ({ otp }) => {
    const data = await verifyMfa({ otp, mfaToken, rememberMe });

    if (data) {
      const { rememberMeToken } = data || {};
      if (rememberMeToken) setRememberMeToken(rememberMeToken);
    }
  };

  const handleGenerateMfa = async (e, form) => {
    e.preventDefault();
    form.restart();
    await generateMfa({ mfaToken });
    verifyReset();
  };

  const handleRememberMeClick = useCallback(() => setRememberMe((prev) => !prev), []);

  const error = generateMfaError?.response?.data?.message || verifyMfaError?.response?.data?.message;
  const verifyAttemptsLeft = verifyMfaError?.response?.data?.params?.verifyAttemptsLeft;

  return (
    <AuthLayout>
      <Box onSubmit={handleVerifyMfa} title={t('verifyMfaTitle')} inside displayErrors={false}>
        {({ submitting, values, form }) => {
          if (error && error !== 'error__invalid_otp') {
            return (
              <>
                <Small>{error}</Small>
                <PrimaryRoundButton onClick={() => push(`/login/admin`)}>{t('backToLoginCaps')}</PrimaryRoundButton>
              </>
            );
          }
          return (
            <>
              <Small>{t('enterCodeMessage', { mfaPhone })}</Small>
              <NumberFieldNoArrows
                autoFocus
                name="otp"
                validate={required}
                type="number"
                step="any"
                title={`${t('OTP')}`}
                style={{ width: '100%' }}
              />
              <CheckBox name="rememberMe" value={rememberMe} onChange={handleRememberMeClick} label={t('rememberMe')} />
              {error === 'error__invalid_otp' && <ErrorText error={error} />}
              {!!verifyAttemptsLeft && (
                <ErrorText error={t('verifyCountAttemptsLeft', { count: verifyAttemptsLeft + 1 })} />
              )}
              <ButtonsContainer>
                <PrimaryRoundButton
                  disabled={submitting || verifyMfaLoading || !values?.otp}
                  isLoading={verifyMfaLoading}>
                  {!verifyMfaLoading && t('verifyCaps')}
                </PrimaryRoundButton>
                <DefaultRoundButton
                  onClick={(e) => handleGenerateMfa(e, form)}
                  isLoading={generateMfaLoading}
                  disabled={generateMfaLoading}>
                  {!generateMfaLoading && t('regenerateCaps')}
                </DefaultRoundButton>
                <SecondaryRoundButton onClick={() => push(`/login/admin`)}>{t('backToLoginCaps')}</SecondaryRoundButton>
              </ButtonsContainer>
            </>
          );
        }}
      </Box>
    </AuthLayout>
  );
};

export default VerifyMfa;
